import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import PageContainer from '../../components/common/page-container'
import Link from '../../components/common/link'

export default (props) => (
  <Layout title="API Integration">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>API Integration</Title>
      </TitleContainer>
    </BackgroundContainer>
    <PageContainer>
      <p>
        Many modern web projects need to talk to other systems. This might be internal tools or systems in your organisation, such as an internal API, or a stock control database, or it might be third party services such as your accounting software, email marketing services, or SMS messaging services.
      </p>
      <p>
        As experienced API developers and software engineers, we build solutions which can reliably integrate with third party tools, but which also allow third party tools to integrate with them.
      </p>
      <h2>Why might you need to integrate with APIs?</h2>
      <ul>
        <li><Link href="/services/software-as-a-service#subscriptions" title="Subscription Billing" className="mb-1">To take payment from your users or manage monthly subscriptions</Link>.</li>
        <li>To connect to other systems, databases and processes in your business.</li>
        <li>To leverage powerful third party services. Why spend time and money building functionality, which other companies have perfected and offer for a fraction of the price?</li>
        <li>To sync data with your accounting, HR, or stock control systems.</li>
        <li>To automate part of your business: send order tracking text messages, dispatch orders via fulfilment centres, calculate shipping costs.</li>
      </ul>
      <p>
        Not only have we integrated systems with hundreds of different APIs over the years, we know which tools and services work well to solve your business problems, so we can get you up and running with a new solution fast!
      </p>
    </PageContainer>
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "integrations.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
